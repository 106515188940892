'use client';

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslate } from 'src/locales';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import { Autoplay, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function SplashScreen() {
  const { t: tConnect } = useTranslate('connect');

  const firstContent = () => (
    <>
      <Stack
        flexGrow={1}
        sx={{
          position: 'relative',
          height: '45vh',
          width: '100%',
          zIndex: 10,
          backdropFilter: 'blur(40px)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage:
            'url(https://images.pexels.com/photos/21419268/pexels-photo-21419268.png?auto=compress&cs=tinysrgb&w=800&lazy=load)',
        }}
      />
      <Stack flexGrow={1} alignItems="center" gap={2} mb={4}>
        <Typography variant="h3">{tConnect('splash.slide1.title')}</Typography>
        <Typography variant="body2" textAlign="center">
          {tConnect('splash.slide1.subtitle')}
        </Typography>
        <Typography variant="caption" textAlign="center">
          {tConnect('splash.slide1.caption')}
        </Typography>
      </Stack>
    </>
  );

  const secondContent = () => (
    <>
      <Stack
        flexGrow={1}
        sx={{
          position: 'relative',
          height: '45vh',
          width: '100%',
          zIndex: 10,
          backdropFilter: 'blur(40px)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage:
            'url(https://images.pexels.com/photos/21377943/pexels-photo-21377943/free-photo-of-trybe.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)',
        }}
      />
      <Stack flexGrow={1} alignItems="center" gap={2} mb={4}>
        <Typography variant="h3">{tConnect('splash.slide2.title')}</Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }} textAlign="center">
          {tConnect('splash.slide2.subtitle')}
        </Typography>
        <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap' }} textAlign="center">
          {tConnect('splash.slide2.caption')}
        </Typography>
      </Stack>
    </>
  );

  const thirdContent = () => (
    <>
      <Stack
        flexGrow={1}
        sx={{
          position: 'relative',
          height: '45vh',
          width: '100%',
          //   mt: 10,
          zIndex: 10,
          backdropFilter: 'blur(40px)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage:
            'url(https://images.pexels.com/photos/21378521/pexels-photo-21378521.png?auto=compress&cs=tinysrgb&w=800&lazy=load)',
        }}
      />
      <Stack flexGrow={1} alignItems="center" gap={2} mb={4}>
        <Typography variant="h3">{tConnect('splash.slide3.title')}</Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }} textAlign="center">
          {tConnect('splash.slide3.subtitle')}
        </Typography>
        <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap' }} textAlign="center">
          {tConnect('splash.slide3.caption')}
        </Typography>
      </Stack>
    </>
  );

  return (
    <Swiper
      scrollbar={{
        hide: false,
      }}
      modules={[Scrollbar, Autoplay]}
      autoplay={{
        delay: 1500,
      }}
      style={{ height: '100%' }}
      className="h-100 w-full rounded-lg"
    >
      <SwiperSlide
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {firstContent}
      </SwiperSlide>
      <SwiperSlide
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {secondContent}
      </SwiperSlide>
      <SwiperSlide
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {thirdContent}
      </SwiperSlide>
    </Swiper>
  );
}
