import { Skeleton, Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
// @mui
import { useTheme } from '@mui/material/styles';
import Carousel, { CarouselArrows, useCarousel } from 'src/components/carousel';
import { useTranslate } from 'src/locales';
import { IEvent } from 'src/types/events';

import EventCard from './event-card';

interface Props extends BoxProps {
  title?: string;
  subheader?: string;
  list: IEvent[];
  isLoading: boolean;
}

export default function LiveEvents({ title, subheader, list, isLoading, sx, ...other }: Props) {
  const theme = useTheme();
  const { t: tHome } = useTranslate('home');
  const carousel = useCarousel({
    slidesToShow: 3,
    draggable: true,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  return (
    <Box sx={{ mb: 1, ...sx }} {...other}>
      <CardHeader
        title={tHome('sections.trending')}
        action={<CarouselArrows onNext={carousel.onNext} onPrev={carousel.onPrev} />}
        sx={{
          p: 0,
          mb: 3,
        }}
      />
      {!isLoading && !list.length && (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          width={1}
          height="15rem"
        >
          <Typography variant="caption" sx={{ color: theme.palette.grey[50] }}>
            {tHome('empty-events')}
          </Typography>
        </Stack>
      )}

      <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
        {!isLoading
          ? list?.length > 0 && list.map((item) => <EventCard key={item.id} event={item} />)
          : [1, 2, 4].map((item, index) => (
              <Skeleton key={index} sx={{ borderRadius: 1.5, height: '20rem', flexShrink: 0 }} />
            ))}
      </Carousel>
    </Box>
  );
}
