'use client';

import { usePathname } from 'next/navigation';
import React, { useState } from 'react';
import { useGetMatches } from 'src/api/hooks/connect';
import { useAuthContext } from 'src/auth/hooks';

type Props = {
  children: React.ReactNode;
};

export default function Layout({ children }: Props) {
  const { user } = useAuthContext(); // Access the user state
  const [rooms, setRooms] = useState<any[]>([]);
  const pathname = usePathname();
  const { data: matchesList } = useGetMatches({ skip: 0, take: 1000 }, !!user);

  return (
    <>
      {/* <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={
          !!messages &&
          !!isNotMyMessage &&
          messages.message.text !== 'ZIZIGULU' &&
          isNotMessagesPath
        }
        onClose={handleClose}
        autoHideDuration={5000}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#000',
          },
        }}
        message={
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{
              '&.MuiSnackbarContent-root': {
                backgroundColor: 'red',
              },
            }}
          >
            <Avatar
              alt=" messages?.message?.metadata?.userPicture"
              src={(messages?.message?.metadata?.userPicture as string) ?? ''}
              sx={{ width: 48, height: 48 }}
            />
            <Typography color="#fff"> {messages?.message?.text}</Typography>
          </Stack>
        }
        action={
          <Button href={`/messages/${messages?.message.roomId}`} color="secondary" size="small">
            Reply
          </Button>
        }
      /> */}

      {children}
    </>
  );
}
