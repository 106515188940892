'use client';

import { Typography, Button, Skeleton } from '@mui/material';
import Badge from '@mui/material/Badge';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useQueryState } from 'nuqs';
import posthog from 'posthog-js';
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useGetLikesCount } from 'src/api/hooks/connect';
import { useGetMyTickets } from 'src/api/hooks/ticket';
import { useAuthContext } from 'src/auth/hooks';
import Iconify from 'src/components/iconify';
import { useTranslate } from 'src/locales';
import { usePathname, useRouter } from 'src/routes/hooks';
import { useUnreadStore } from 'src/stores/useUnreadStore';
import { useChatContext } from 'stream-chat-react';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Only create scroll trigger on client side
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Slide appear={false} direction="up" in={!trigger}>
      {children}
    </Slide>
  );
}

type PathNumberDictionaryProps = {
  [key: string]: number;
};
const pathNumberDictionary: PathNumberDictionaryProps = {
  '/': 0,
  '/me/events/': 1,
  '/for-you/': 5,
  '/connects/': 2,
  '/messages/': 3,
};

const PWAPrompt = ({ show, onClose, t }: { show: boolean; onClose: () => void; t: any }) => (
  <Slide direction="up" in={show} mountOnEnter unmountOnExit>
    <Stack
      alignItems="center"
      gap={2}
      sx={{
        position: 'absolute',
        bottom: 0,
        zIndex: 20,
        p: 3,
        width: '100%',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: '#000',
      }}
    >
      <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
        {t('pwa.full-app-experience')}
      </Typography>
      <Typography sx={{ fontSize: '15px' }}>{t('pwa.enjoy-more-options')}</Typography>
      <Button
        variant="contained"
        href="/tutorial"
        onClick={() => posthog.capture('add_to_homepage_tuto_clicked')}
        sx={{
          width: '100%',
          fontSize: '15px',
          padding: '10px',
        }}
      >
        {t('pwa.add-to-home-screen')}
      </Button>
      <Button variant="text" onClick={onClose} sx={{ fontSize: '15px' }}>
        {t('pwa.not-now')}
      </Button>
    </Stack>
  </Slide>
);

export default function TabBar() {
  const [isMounted, setIsMounted] = useState(false);
  const [showPWA, setShowPWA] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const router = useRouter();
  const { t } = useTranslate('common');
  const [_, setOpenLogin] = useQueryState('login');
  const { unreadCount, setUnreadCount } = useUnreadStore();

  const { client } = useChatContext();

  const pathname = usePathname();
  const { user, loading } = useAuthContext();
  const { data: likesCounts } = useGetLikesCount(!!user);
  const { data: ticketsList } = useGetMyTickets(!!user);

  const numberOfEvent = useMemo(() => {
    const currentDate = new Date();

    return ticketsList?.filter((ticket) => new Date(ticket?.endDate || '') > currentDate)?.length;
  }, [ticketsList]);

  useEffect(() => {
    setIsMounted(true);
    const { userAgent } = navigator;
    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));

    // Check PWA status
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setShowPWA(false);
      if (user?.id) {
        posthog.capture('user_has_trybe_pwa_installed', {
          userId: user?.id,
          email: user?.email,
        });
      }
    } else {
      const pwaStatus = sessionStorage.getItem('showPWA');
      setShowPWA(pwaStatus ? pwaStatus === 'true' : true);
    }
  }, [user]);

  const onClosePopup = useCallback(() => {
    setShowPWA(false);
    sessionStorage.setItem('showPWA', 'false');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showThePopup: boolean = useMemo(
    () =>
      sessionStorage.getItem('showPWA')
        ? Boolean(sessionStorage.getItem('showPWA') === 'true')
        : showPWA,
    [showPWA]
  );

  useEffect(() => {
    client.on((event) => {
      if (event.unread_channels !== undefined) {
        setUnreadCount(event.unread_channels as number);
      }
    });

    return () => {
      // Clean up event listeners
      client.off('notification.mark_all_read', () => null);
    };
  }, [client, setUnreadCount]);

  const handleRouting = useCallback(
    (path: string) => {
      if (!user && path !== '/') {
        setOpenLogin('open');
      } else {
        router.push(path);
      }
    },
    [router, setOpenLogin, user]
  );

  if (!isMounted) return null;

  return (
    <>
      <CssBaseline />
      <HideOnScroll>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 10 }} elevation={3}>
          <Slide direction="up" in={showThePopup && isMobile} mountOnEnter unmountOnExit>
            <Stack
              alignItems="center"
              gap={2}
              sx={{
                position: 'absolute',
                bottom: 0,
                zIndex: 20,
                p: 3,
                width: '100%',
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                backgroundColor: '#000',
              }}
            >
              <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
                {t('pwa.full-app-experience')}
              </Typography>
              <Typography sx={{ fontSize: '15px' }}>{t('pwa.enjoy-more-options')}</Typography>
              <Button
                variant="contained"
                href="/tutorial"
                onClick={() => posthog.capture('add_to_homepage_tuto_clicked')}
                sx={{
                  width: '100%',
                  fontSize: '15px',
                  padding: '10px',
                }}
              >
                {t('pwa.add-to-home-screen')}
              </Button>
              <Button variant="text" onClick={() => onClosePopup()} sx={{ fontSize: '15px' }}>
                {t('pwa.not-now')}
              </Button>
            </Stack>
          </Slide>
          <BottomNavigation
            showLabels
            sx={{ height: 70, backgroundColor: '#000' }}
            value={pathNumberDictionary[pathname]}
          >
            <BottomNavigationAction
              label={t('tab-bottom.explore')}
              onClick={() => {
                handleRouting('/');
                posthog.capture('navbar_explore_clicked');
              }}
              sx={{
                justifyContent: 'flex-start',
                paddingTop: '0.5rem',
                '& .Mui-selected': { fontSize: '13px' },
                '& .MuiBottomNavigationAction-label': { fontSize: '13px' },
              }}
              icon={<Iconify icon="carbon:explore" sx={{ height: 23, width: 23 }} />}
            />
            <BottomNavigationAction
              label={t('tab-bottom.tickets')}
              onClick={() => {
                handleRouting('/me/events/?type=ticket');
                posthog.capture('tickets_pages_clicked');
              }}
              sx={{
                justifyContent: 'flex-start',
                paddingTop: '0.5rem',
                '& .Mui-selected': { fontSize: '13px' },
                '& .MuiBottomNavigationAction-label': { fontSize: '13px' },
              }}
              icon={
                <Badge badgeContent={numberOfEvent} color="primary">
                  <Iconify icon="solar:ticket-bold" sx={{ height: 23, width: 23 }} />{' '}
                </Badge>
              }
            />
            <BottomNavigationAction
              label={t('tab-bottom.connects')}
              onClick={() => {
                handleRouting('/connects');
                posthog.capture('navbar_connects_clicked');
              }}
              sx={{
                justifyContent: 'flex-start',
                paddingTop: '0.5rem',
                '& .Mui-selected': { fontSize: '13px' },
                '& .MuiBottomNavigationAction-label': { fontSize: '13px' },
              }}
              icon={
                <Badge badgeContent={likesCounts} color="primary">
                  <Iconify icon="akar-icons:thunder" sx={{ height: 23, width: 23 }} />
                </Badge>
              }
            />
            <BottomNavigationAction
              label={t('tab-bottom.chat')}
              onClick={() => {
                handleRouting('/messages');
                posthog.capture('navbar_messages_clicked');
              }}
              sx={{
                justifyContent: 'flex-start',
                paddingTop: '0.5rem',
                '& .Mui-selected': { fontSize: '13px' },
                '& .MuiBottomNavigationAction-label': { fontSize: '13px' },
              }}
              icon={
                <Badge
                  badgeContent={unreadCount || 0}
                  invisible={unreadCount === 0}
                  color="primary"
                >
                  <Iconify icon="solar:chat-line-line-duotone" sx={{ height: 23, width: 23 }} />
                </Badge>
              }
            />
            {!user && !loading && (
              <BottomNavigationAction
                label={t('tab-bottom.login')}
                sx={{ justifyContent: 'flex-start', paddingTop: '0.5rem' }}
                icon={<Iconify icon="eva:person-fill" sx={{ height: 23, width: 23 }} />}
                onClick={() => {
                  setOpenLogin('open');
                  posthog.capture('navbar_login_clicked');
                }}
              />
            )}
            {loading && !user && (
              <Stack direction="column">
                <BottomNavigationAction icon={<Skeleton width={50} height={50} />} />
                <Stack sx={{ height: 15 }} />
              </Stack>
            )}
          </BottomNavigation>
        </Paper>
      </HideOnScroll>
    </>
  );
}
