var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a8d866a02fd730237652e52768262c1a0d460f8b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { SENTRY_DSN } from 'src/config-global';

Sentry.init({
  dsn: SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: process.env.NODE_ENV === 'development',

  profilesSampleRate: 1.0,
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.1,
  replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.1,
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production

  tracePropagationTargets: ['localhost', 'trybe.fm', /^https:\/\/[\w-]+\.trybe\.fm/],

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Replay configuration
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,

      // Session sampling
      sessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 0,
      errorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.1,

      // Network capturing configuration
      networkDetailAllowUrls: ['trybe.fm'],
      networkRequestHeaders: ['trybe.fm'],
      networkResponseHeaders: ['trybe.fm'],
      // Additional security measures
      mutationBreadcrumbLimit: 500,
      mutationLimit: 1000,
    }),
    ...(process.env.NODE_ENV === 'production'
      ? [
          new Sentry.BrowserProfilingIntegration({
            profilesSampleRate: 0.1,
          }),
        ]
      : []),
  ],

  ignoreErrors: [
    // Add any errors you want to ignore
    'ResizeObserver loop limit exceeded',
    'Non-Error promise rejection captured',
  ],

  beforeSend(event) {
    // Don't send events in development
    if (process.env.NODE_ENV !== 'production') {
      return null;
    }

    return event;
  },
});
