import { TextField, ListSubheader, Box, Button, Menu, MenuItem, Backdrop } from '@mui/material';
import { useGeolocation } from '@uidotdev/usehooks';
import { first, isEmpty } from 'lodash';
import { useState, ChangeEvent, MouseEvent, useRef, useEffect } from 'react';
import { useTranslate } from 'src/locales';
import { useLocationStore } from 'src/stores/useLocationStore';

import { AggregatedCityModel, City } from '../../types';
import Iconify from '../iconify';

interface Props {
  data?: AggregatedCityModel[];
}

export default function CitySelector({ data }: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
  const { place, setLat, setLng, setPlace } = useLocationStore();
  const { latitude: userLat, longitude: userLng, loading } = useGeolocation();
  const [selectedCity, setCity] = useState<City>();

  const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371;
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };
  const { t } = useTranslate('common');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const searchFieldRef = useRef<HTMLInputElement>(null);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleCitySelect = (city: City) => {
    setAnchorEl(null);
    updateQueryParams(city);
    setSearchQuery('');
  };

  const updateQueryParams = (city: City) => {
    setCity(city);
    setLat(city.latitude.toString());
    setLng(city.longitude.toString());
    setPlace(city.name);
  };

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchQuery('');
  };

  useEffect(() => {
    if (anchorEl && searchFieldRef.current) {
      searchFieldRef.current.focus();
    }
  }, [anchorEl]);

  useEffect(() => {
    if (data && !isEmpty(data) && userLat && userLng && !place && !selectedCity) {
      let nearestCity: City | null = null;
      let minimumDistance = 50;

      data.forEach((country) => {
        country.cities.forEach((city) => {
          const distance = calculateDistance(userLat, userLng, city.latitude, city.longitude);

          if (Number(distance.toFixed(0)) < minimumDistance) {
            minimumDistance = distance;
            nearestCity = city;
          }
        });
      });

      if (nearestCity) {
        updateQueryParams(nearestCity);
      }
    } else if (
      data &&
      !isEmpty(data) &&
      !userLat &&
      !place &&
      !userLng &&
      !selectedCity &&
      !loading
    ) {
      const filterCountry = data.filter((country) => country.countryCode === 'CA');
      let defaultCity = (
        filterCountry ? first(filterCountry)?.cities[1] : data[0].cities[1]
      ) as City;

      if (place) {
        data.forEach((country) => {
          const city = country.cities.find((c) => c.normalizedName === place);
          if (city) {
            defaultCity = city;
          }
        });
      } else {
        const canadaCitiesData = data.find((d) => d.countryCode === 'CA');
        const city = canadaCitiesData?.cities.find((c) => c.normalizedName === 'montreal');
        if (city) {
          defaultCity = city;
        }
      }
      // onSelectCity(defaultCity);
      updateQueryParams(defaultCity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, userLat, selectedCity, userLng, place, loading]);

  const renderMenuItems = () => {
    if (!data) return [];

    return data.reduce((acc: JSX.Element[], country) => {
      const filteredCities = country.cities.filter((city) =>
        city.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (filteredCities.length > 0) {
        acc.push(
          <ListSubheader
            key={`header-${country.countryCode}`}
            sx={{
              width: '100%',
              bgcolor: 'transparent',
              pl: 1,
              position: 'relative',
            }}
          >
            {regionNames.of(country.countryCode)?.toUpperCase()}
          </ListSubheader>
        );
        filteredCities.forEach((city) => {
          acc.push(
            <MenuItem
              key={city.id}
              selected={selectedCity?.id === city.id}
              onClick={() => handleCitySelect(city)}
              sx={{
                marginBottom: 0,
              }}
            >
              {city.name}
            </MenuItem>
          );
        });
      }

      return acc;
    }, []);
  };

  return (
    <Box>
      <Button
        variant="black"
        color="primary"
        onClick={handleButtonClick}
        startIcon={<Iconify width={20} icon="fluent:location-48-filled" />}
        sx={{
          backgroundColor: anchorEl ? 'white' : 'black',
          color: anchorEl ? 'black' : 'white',
          '&:hover': {
            backgroundColor: anchorEl ? 'white' : 'black',
          },
        }}
      >
        {selectedCity ? selectedCity.name : t('buttons.location')}
      </Button>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: 100,
        }}
        open={Boolean(anchorEl)}
      >
        {data && data.length > 0 && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              maxHeight: '430px',
              maxWidth: '300px',
              '&::-webkit-scrollbar': { display: 'none' },
              mt: 1,
              ml: -0.5,
              '& .MuiMenu-list': {
                paddingRight: '0px',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <TextField
              label="Find a city"
              value={searchQuery}
              onChange={handleSearchChange}
              fullWidth
              variant="standard"
              inputRef={searchFieldRef}
              sx={{
                '& .MuiFormLabel-root': {
                  px: 1,
                },
              }}
              onKeyDown={(e) => e.stopPropagation()}
            />

            {renderMenuItems()}
          </Menu>
        )}
      </Backdrop>
    </Box>
  );
}
