export enum CartLockType {
  TICKET = 'TICKET',
  SHOP_ITEM = 'SHOP_ITEM',
}

export interface LockedQuantity {
  type: CartLockType;
  itemId: number;
  totalQty: number;
}
