'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';

import { POSTHOG } from '../config-global';
import GetStreamProvider from './get-stream-provider';

const isProdEnvironment = typeof window !== 'undefined' && window.location.host === 'trybe.fm';

export function PHProvider({ children }: PropsWithChildren) {
  const [postHogInitialized, setPostHogInitialized] = useState(false);
  const { user, chatToken, loading } = useAuthContext(); // Access the user from AuthContext

  useEffect(() => {
    if (isProdEnvironment && POSTHOG.publicKey && !postHogInitialized) {
      posthog.init(POSTHOG.publicKey, {
        api_host: POSTHOG.host,
        capture_pageview: true, // Disable automatic pageview capture
        capture_pageleave: true,
        session_recording: {
          maskInputOptions: {
            email: false,
            tel: false,
          },
        },
      });
      setPostHogInitialized(true); // Mark Posthog as initialized
    }
  }, [postHogInitialized, user]);

  if (loading) return <PostHogProvider client={posthog}>{children}</PostHogProvider>;

  const renderWithPostHogAndAbly = (
    <GetStreamProvider>
      <PostHogProvider client={posthog}>{children}</PostHogProvider>
    </GetStreamProvider>
  );

  const renderWithAblyOnly = <GetStreamProvider>{children}</GetStreamProvider>;

  if (user && chatToken) {
    if (isProdEnvironment && postHogInitialized) {
      return renderWithPostHogAndAbly;
    }
    return renderWithAblyOnly;
  }
  return children;
}
