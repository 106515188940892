import { useState } from 'react';
import {
  EventConnectMatch,
  EventConnectRequest,
  IAttendee,
  IConnectRequest,
  IConnectRequestResponse,
  ICreateChannel,
  IEventParticipant,
  IParticipation,
} from 'src/types';
import { Pagination, PaginationQuery, PaginationResponse } from 'src/types/pagination';
import { toQueryParams } from 'src/utils';
import axios, { endpoints, axiosInstance } from 'src/utils/axios';
import { mutate } from 'swr';

export const useSendParticipation = (): {
  sendParticipation: (id: string, request: IParticipation) => Promise<void>;
  loading: boolean;
} => {
  const [loading, setLoading] = useState(false);

  const sendParticipation = async (id: string, request: IParticipation) => {
    setLoading(true);

    try {
      const URL = endpoints.connect.participate(id);
      await axios.post<IEventParticipant>(URL, request);
      mutate(endpoints.event.detail(id));
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return { sendParticipation, loading };
};

export const useDisconnect = (): {
  disconnect: (matchId: string, channelId: string) => Promise<void>;
  loading: boolean;
} => {
  const [loading, setLoading] = useState(false);

  const disconnect = async (matchId: string, channelId: string) => {
    setLoading(true);

    try {
      const URL = endpoints.connect.matches.unMatch(matchId);
      await axios.post(URL, matchId);
      mutate('/messages/');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return { disconnect, loading };
};

export async function createTrybeChannel(
  payload: ICreateChannel
): Promise<IConnectRequestResponse> {
  const URL = endpoints.connect.channel(payload.matchId);
  const { data } = await axiosInstance.post<IConnectRequestResponse>(URL, payload);
  return data;
}

export async function getMatchesList(
  params?: PaginationQuery
): Promise<Pagination<EventConnectMatch>> {
  const URL = endpoints.connect.matches.getMatches;
  const { data } = await axiosInstance.get<Pagination<EventConnectMatch>>(
    `${URL}?${toQueryParams(params)}`
  );
  return data;
}

export async function sendLikeRequest(
  id: string,
  request: IConnectRequest
): Promise<IConnectRequestResponse> {
  const URL = endpoints.connect.request(id);
  const { data } = await axios.post<IConnectRequestResponse>(URL, request);
  return data;
}

export async function getAttendeesList(
  id: string,
  query?: string
): Promise<PaginationResponse<IAttendee>> {
  const url = query ? `${endpoints.connect.list(id)}?${query}` : endpoints.connect.list(id);
  const { data } = await axios.get<PaginationResponse<IAttendee>>(url);
  return data;
}

export async function deleteAttendeesSeed(eventId: string): Promise<void> {
  const URL = endpoints.connect.deleteAttendeesSeed(eventId);
  await axios.delete(URL);
}
export async function getLikeList(): Promise<Pagination<EventConnectRequest>> {
  const URL = endpoints.connect.likes;
  const { data } = await axios.get<Pagination<EventConnectRequest>>(URL);
  return data;
}

export async function getLikeCount(): Promise<number> {
  const URL = endpoints.connect.likesCount;
  const { data } = await axios.get<number>(URL);
  return data;
}
