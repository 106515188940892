// @mui
import Stack from '@mui/material/Stack';

import CitySelector from '../../components/autocomplete/city-selector';
import { AggregatedCityModel } from '../../types';

// ----------------------------------------------------------------------

interface Props {
  data?: AggregatedCityModel[];
}

export default function LocationAndSearch({ data }: Props) {
  return (
    <Stack direction="row" alignItems="center">
      <CitySelector data={data} />
    </Stack>
  );
}
