import { useMutation, useQuery } from '@tanstack/react-query';
import queryClient from 'src/services/queryClient';
import { DeleteTransferTicketDTO, TransferTicketDTO } from 'src/types';

import { deleteTransferredTicket, getTicketsGroupedByEvent, transferTicket } from '../ticket';

export function useGetMyTickets(enabled?: boolean) {
  return useQuery({
    queryKey: ['tickets'],
    queryFn: async () => getTicketsGroupedByEvent(),
    refetchOnWindowFocus: true,
    enabled,
  });
}

export function useTransferTicket() {
  return useMutation({
    mutationFn: async (data: TransferTicketDTO) => transferTicket(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['tickets'] });
    },
  });
}

export function useDeleteTransfer() {
  return useMutation({
    mutationFn: async (data: DeleteTransferTicketDTO) =>
      deleteTransferredTicket(data.ticketId, data.transferredId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['tickets'] });
    },
  });
}
